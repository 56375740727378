<template>
    <!-- Page Content -->
    <div class="auth__content hero-static content content-full" v-show="isLoaded">
        <!-- Avatar -->
        <div class="py-30 px-5 text-center content-header__wrapper">
            <img class="content-header__logo" src="~assets/img/wikiworks-logo.png" height="32" width="194"
                 alt="Wikiworks">
        </div>
        <!-- END Avatar -->

        <!-- Unlock Content -->
        <div class="row justify-content-center px-5">
            <transition tag="div" name="fade" mode="out-in" class="col-sm-8 col-md-6 col-lg-6 d-flex justify-content-center autorization__wrapper">
                <div class="modal__block autorization__content" :key="1" v-if="status === 1">
                    <h3 class="autorization__title">Истекло время подтверждения</h3>
                    <div class="autorization__tab-content">
                        <!-- Вкладка входа -->
                        <div class="autorization__tab">
                            <div class="autorization__send-message">
                                <div>
                                    Пожалуйста, отправьте повторный <router-link class="" to="/password_reset">запрос</router-link> для восстановления пароля
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal__block autorization__content" :key="2" v-if="status === 2">
                    <h3 class="autorization__title">Восстановление пароля</h3>
                    <div class="autorization__tab-content">
                        <!-- Вкладка входа -->
                        <div class="autorization__tab">
                            <!-- Форма входа -->
                            <ValidationObserver
                                tag="form"
                                ref="observer"
                                @submit.prevent="changePassword(inputs)"
                                class="form"
                                id="id_login_form"
                            >
                                <div class="form__content">
                                    <ValidationProvider
                                        tag="div"
                                        class="form__group flex-column"
                                        :rules="`required`"
                                        vid="password"
                                        v-slot="{errors}"
                                    ><div
                                        @click="isNewPasswordHidden = !isNewPasswordHidden"
                                        class="form__display-pass js-display-pass"
                                        :class="{'form__display-pass--closed' : !isNewPasswordHidden}"
                                    ></div>
                                        <input
                                            v-model="inputs.newPassword"
                                            :type="isNewPasswordHidden ? 'password' : 'text'"
                                            placeholder="Новый пароль"
                                            name="password"
                                            class="form__control_auth pass"
                                        >
                                        <span class="v-error animated fadeInDown" v-if="errors.length">
                                            {{ errors[0] }}
                                        </span>
                                        <ValidationProvider
                                            tag="span"
                                            class="v-error animated fadeInDown"
                                            vid="non_field_errors"
                                            v-slot="{errors}"
                                        >
                                            {{ errors[0] }}
                                        </ValidationProvider>
                                    </ValidationProvider>
                                    <ValidationProvider
                                        tag="div"
                                        class="form__group flex-column"
                                        :rules="`required`"
                                        vid="password_2"
                                        v-slot="{errors}"
                                    ><div
                                        @click="isConfirmPasswordHidden = !isConfirmPasswordHidden"
                                        class="form__display-pass js-display-pass"
                                        :class="{'form__display-pass--closed' : !isConfirmPasswordHidden}"
                                    ></div>
                                        <input
                                            v-model="inputs.confirmPassword"
                                            :type="isConfirmPasswordHidden ? 'password' : 'text'"
                                            placeholder="Повторите пароль"
                                            name="password"
                                            class="form__control_auth pass"
                                        >
                                        <span class="v-error animated fadeInDown" v-if="errors.length">
                                            {{ errors[0] }}
                                        </span>
                                        <ValidationProvider
                                            tag="span"
                                            class="v-error animated fadeInDown"
                                            vid="non_field_errors"
                                            v-slot="{errors}"
                                        >
                                            {{ errors[0] }}
                                        </ValidationProvider>
                                    </ValidationProvider>
                                    <div class="form__group form__group--top">
                                        <button type="submit"
                                                class="autorization__btn btn btn_first btn_first-fill autorization__btn--margin">
                                            Изменить пароль
                                        </button>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
                <div class="modal__block autorization__content autorization__content--short" :key="3" v-if="status === 3">
                    <h3 class="autorization__title autorization__title--confirm">Пароль успешно изменен</h3>
                    <div class="autorization__tab-content">
                        <!-- Вкладка входа -->
                        <div class="autorization__tab">
                            <div class="form__group">
                                <router-link to="/login" class="autorization__btn btn btn_first btn_first-fill autorization__btn--confirm">Войти</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <!-- END Unlock Content -->
    </div>
    <!-- END Page Content -->
</template>

<script>
import session from '@/api/session'

export default {
    name: 'NewPassword',
    data() {
        return {
            inputs: {
                newPassword: '',
                confirmPassword: '',
            },
            isNewPasswordHidden: true,
            isConfirmPasswordHidden: true,
            hash: null,
            isLoaded: false,
            status: 2
        }
    },
    methods: {
        async changePassword ({newPassword, confirmPassword}) {
            let isValid = await this.$refs.observer.validate();
            if (isValid) {
                try {
                    let params = {
                        password: newPassword,
                        password_2: confirmPassword,
                        code: this.hash
                    }
                    await session.post('/api/v1/change-password/', params);
                    this.status = 3;
                } catch (error) {
                    this.$refs.observer.setErrors(error.response.data);
                }

            }
        },
        async checkIsExpired(hash) {
            try {
                const request = await session.post('/api/v1/confirm-query/', { code: hash });
                return true;
            } catch (e) {
                return false
            }
        }
    },
    async created () {
        this.hash = this.$route.params.hash;
        if (!this.hash || !await this.checkIsExpired(this.hash)) {
            this.status = 1;
        }
        this.isLoaded = true;
    }
}
</script>

<style lang="scss" scoped>
@import "auth";
</style>
